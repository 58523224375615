import React from 'react'
import AdminCategoryList from '../../../components/dashboard/admin/categories/admin-category-list';

const AdminCategoryListPage = () => {
  return (
    <>
      <AdminCategoryList />
    </>
  )
}

export default AdminCategoryListPage