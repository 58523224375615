import React from "react";
import "./logo-loading.scss";
import { Spinner } from "react-bootstrap";

const LogoLoading = ({ size }) => {
	return (
		<div className="logo-loading">
			<div className="loading-wrapper">
				<Spinner variant="warning" />
			</div>
		</div>
	);
};

export default LogoLoading;
