import React from 'react'
import AdminContactMessage from '../../../components/dashboard/admin/contact-messages/admin-contact-message'

const AdminContactMessagePage = () => {
  return (
    <>
      <AdminContactMessage />
    </>
  )
}

export default AdminContactMessagePage