import React from 'react'
import AdminTourRequest from '../../../components/dashboard/admin/tour-requests/admin-tour-request';

const AdminTourRequestPage = () => {

    return (
        <>
            <AdminTourRequest />
        </>
    )
}

export default AdminTourRequestPage