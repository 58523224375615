import React from 'react';
import Dashboard from '../../../components/dashboard/admin/home/dashboard';

const AdminDashboardPage = () => {
  return (
    <>
      <Dashboard />
    </>
  )
}

export default AdminDashboardPage