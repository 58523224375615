import React from 'react'
import AdminAdvertType from '../../../components/dashboard/admin/advert-types/admin-advert-type'

const AdminAdvertTypesPage = () => {
    return (
        <>
            <AdminAdvertType />
        </>
    )
}

export default AdminAdvertTypesPage