export const config = {
  api: {
    baseUrl: 'http://localhost:8080',
  },
  project: {
    name: "GetLandEstate",
    description: "GetLandEstate  is an online platform for buying, selling, or renting various properties like houses, apartments, land, and commercial real estate. It also provides users with the convenience of easily requesting property tours and efficiently managing incoming tour requests, thereby offering a comprehensive solution for all their real estate requirements.",
  },
  settings: {
    languages: [
      { code: 'en', name: 'English', flag: "/icons/flags/uk.png" },
      { code: 'tr', name: 'Türkçe', flag: "/icons/flags/tr.png" },
      { code: 'fr', name: 'Français', flag: "/icons/flags/fr.png" },
      { code: 'de', name: 'Deutsch', flag: "/icons/flags/de.png" },
      { code: 'es', name: 'Español', flag: "/icons/flags/es.png" }
    ],
  },
  banner: {
    images: [
      { alt: 'house', source: '/images/content/banner-slide/house.png' },
      { alt: 'couple', source: '/images/content/banner-slide/couple.png' },
      { alt: 'bluehouse', source: '/images/content/banner-slide/bluehouse.png' },
      { alt: 'camping', source: '/images/content/banner-slide/camping.png' },
      { alt: 'buildings', source: '/images/content/banner-slide/buildings.png' },
      { alt: 'buildingwindows', source: '/images/content/banner-slide/buildingwindows.png' },
      { alt: 'redroof', source: '/images/content/banner-slide/redroof.png' },
      { alt: 'floatinghouse', source: '/images/content/banner-slide/floatinghouse.png' },
    ],
  },
  contact: {
    center: {
      phone: "+90 (541) 123-2225",
      email: "info@getlandestate.com",
      address: "318 N Los Angeles St, Los Angeles, CA 90012, USA",
      mapURL: "https://maps.app.goo.gl/t2bXusPKPwDtSxx7A",
      mapEmbedURL: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.5934599814536!2d-118.2401244801679!3d34.05429811973104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c645ebb45521%3A0x1fa84c09943d861e!2s318%20N%20Los%20Angeles%20St%2C%20Los%20Angeles%2C%20CA%2090012%2C%20USA!5e0!3m2!1sen!2sbe!4v1711662831556!5m2!1sen!2sbe",
    },
    offices: [
      {
        id: 1,
        name: "PARIS",
        address: "10 Rue de Bretagne, 75003 Paris, France",
        mapURL: "https://maps.app.goo.gl/4CzLkNu6vSUgqqB5A",
        phone: "+33 1 53 76 00 00",
        image: "paris.png"
      },
      {
        id: 2,
        name: "LONDON",
        address: "7 Newgate St, London EC1A 7AE, UK",
        mapURL: "https://maps.app.goo.gl/DaJwmVxviPBYohQu5",
        phone: "+44 8880 733700",
        image: "london.png"
      },
      {
        id: 3,
        name: "VANCOUVER",
        address: "905 Hornby St, Vancouver, BC V6Z 1V3, Canada",
        mapURL: "https://maps.app.goo.gl/MG2hKm25u6F157aE7",
        phone: "+1 613 555 0172",
        image: "istanbul.png"
      }
    ]
  },
  pageRoles: {
    myProfile: ["ADMIN", "MANAGER", "CUSTOMER"],
    myAdverts: ["ADMIN", "MANAGER", "CUSTOMER"],
    myFavorites: ["ADMIN", "MANAGER", "CUSTOMER"],
    dashboard: ["ADMIN", "MANAGER"]
  },
  selectRoles: {
    roles: ["ADMIN", "MANAGER", "CUSTOMER"]
  },
  advertsStatus: {
    status: [{ label: "All", value: "" }, { label: "Pending", value: 0 }, { label: "Activated", value: 1 }, { label: "Rejected", value: 2 }]
  },
  tourRequestStatus: {
    status: [{ label: "All", value: "" }, { label: "Pending", value: 0 }, { label: "Approved", value: 1 }, { label: "Declined", value: 2 }, { label: "Canceled", value: 3 }]
  }

}