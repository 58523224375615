import React from 'react';
import Users from '../../../components/dashboard/admin/users/admin-users';

const UsersPage = () => {
  return (
    <>
      <Users />
    </>
  )
}

export default UsersPage